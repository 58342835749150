<template>
  <div class="publicdetailsmorezjk">
    <div class="boxmorezjk" v-html="datailArr.content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      datailArr: {},
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.home();
  },
  methods: {
    async home() {
      let id;
      if(this.language == 206) {
        id = 1;
      } else if (this.language == 205) {
        id = 3;
      }
      let result = await this.$http({
        url: `/api/scenicApi/getScenicById/${id}`,
        // url: `/api/travelStoreApi/getDetail/${this.id}`,
        method: "get",
      });
      this.datailArr = result.data.data;
      console.log(result);
    },
  },
};
</script>

<style scoped>
.publicdetailsmorezjk {
  width: 1200px;
  /* height: 1000px; */
  /* background: #cecece; */
  margin: 0px auto;
  overflow: hidden;
}
.publicdetailsmorezjk .boxmorezjk {
  width: 100%;
  height: 1500px;
  /* background: green; */
  margin-top: 88px;
}
</style>