<template>
  <div class="food-box">
      <div class="public-box-img"
      :style="{backgroundImage: 'url(' + coverImgUrl + ')', backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat'}"
      ></div>
      <Djmjseven></Djmjseven>
  </div>
</template>

<script>
import Djmjseven from "../djmjseven/djmjseven";
export default {
  components: {
    Djmjseven
  },
  data() {
    return {
      coverImgUrl: 'https://qiniu.image.citgroup.cn/sichuan/qiqushan/cms/qqscolumn.jpg'
    }
  },
  mounted() {
    console.log(this.$i18n.locale);
    if(this.$i18n.locale == 'en') {
      this.coverImgUrl = 'https://qiniu.image.citgroup.cn/sichuan/qiqushan/cms/information.png';
    }
  }
};
</script>

<style>
.food-box{
    overflow: hidden;
}
.food-box .public-box-img{
    width: 100%;
    height: 600px;
    /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/chengshimingpian.png) no-repeat; */
    margin: 0px auto ;
}
</style>