<template>
  <div class="publicdetailsscoped">
    <div class="boxscoped">
      <div class="body-title">
        <h3>{{ detailArr.title }}</h3>
      </div>
      <div class="sub">
        <!-- <div class="pubtime" v-if="detailArr.createDate">{{ $t('content.releaseTime') }}：<i>{{ detailArr.createDate }}</i> </div> -->
        <div class="source" v-if="detailArr.source">{{ $t('content.source') }}：<i>{{ detailArr.source }}</i></div>
      </div>
      <hr>
      <div class="" v-html="detailArr.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      detailArr: {content: '', title: '', createDat: '', source: ''},
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.detailApi = this.$route.query.detailApi;
    this.home();
  },
  methods: {
    async home() {
      let _api = '';
      let _id_params = '';
      if (this.detailApi) {
        _api = this.detailApi;
      } else {
        _api = '/api/travelStoreApi/getDetail/';
      }
      let _last_latter = _api.substr(-1, 1);
      if (isNaN(_last_latter)) {
        if (_api.substr(-1, 1) !== '/') {
          _api += '/' + this.id;
        }
      }
      // if (this.id) {
      //   _id_params = '?id='+this.id;
      // }
      let result = await this.$http({
        url: _api + _id_params,
        method: "get",
      });
      this.detailArr = result.data.data;
      console.log(this.detailArr);
      if(this.detailArr) {
        console.log(111);
      }else {
        this.detailArr = {content: '<img style="margin: 0 auto;display: block;" src="http://qiniu.image.citgroup.cn/heibeizhangjiakou/pc/404.png" alt="">'};
      };
    },
  },
};
</script>

<style>
.body-title{
  padding-top: 20px;
  text-align: center;
}
.body-title h3 {
  font-size: 30px;
}
.sub {
  display: flex;
  justify-content: center;
}
.source{
  margin-left: 30px;
}
.publicdetails {
  width: 1200px;
  /* height: 100vh; */
  background: #cecece;
  margin: 0 auto;
  overflow: hidden;
}
.publicdetails .box {
  width: 100%;
  height: auto;
  margin-top: 88px;
}
hr {
  margin: 14px 0;
}

.publicdetailsscoped {
  width: 1200px;
  /* height: 100vh; */
  /* background: #cecece; */
  margin: 0 auto;
  overflow: hidden;
}
.publicdetailsscoped .boxscoped {
  width: 100%;
  height: auto;
  margin-top: 88px;
}
</style>